/**
 * @generated SignedSource<<bb1b500037a10125a54a7fade4acfb5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderContentQuirkyPurple_content$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HeaderMenu_content" | "HeaderSearchBar_data" | "ProfileMenu_data">;
  readonly " $fragmentType": "HeaderContentQuirkyPurple_content";
};
export type HeaderContentQuirkyPurple_content$key = {
  readonly " $data"?: HeaderContentQuirkyPurple_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderContentQuirkyPurple_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderContentQuirkyPurple_content",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderMenu_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderSearchBar_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileMenu_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8745423c8dc6c38be222b84fb2e92826";

export default node;
