import { pxToRem } from '../pxToRem';
import type * as Fonts from './font-types';

const FontPalette = {
  Default: `'Korolev', sans-serif`,
  Brand: `'Poppins', sans-serif`,
  Alternate: `'Poppins', sans-serif`,
};

export const Font: Fonts.FontType = {
  Body: FontPalette.Default,
  Button: FontPalette.Brand,
  Heading: FontPalette.Brand,
  HeadingAlt: FontPalette.Brand,
  Primary: FontPalette.Brand,
  Tiny: FontPalette.Default,
};

export const FontHeadingSize: Fonts.FontHeadingSizeType = {
  Huge: pxToRem(48),
  Big: pxToRem(40),
  Normal: pxToRem(32),
  Small: pxToRem(28),
  Tiny: pxToRem(24),
};

export const FontTextSize: Fonts.FontTextSizeType = {
  Huge: pxToRem(22),
  Big: pxToRem(19),
  Normal: pxToRem(16),
  Small: pxToRem(14),
  Tiny: pxToRem(12),
};
