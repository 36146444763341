import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,
  Text: Color.Primitive.AccentContrast + '80',
  Link: Color.Primitive.AccentContrast,
  Bullets: Color.Primitive.AccentContrast + '20',
  Background: Color.Primitive.Accent,
  BrandLogoText: Color.Primitive.AccentContrast,
};
