import Logo from './svg/pinata-logo.svg';
import type { LogotypeType } from './logo-variants-types';

export const Logos: LogotypeType = {
  Horizontal: Logo,
  HorizontalInverse: Logo,
  Vertical: Logo,
  VerticalInverse: Logo,
  Icon: Logo,
  IconInverse: Logo,
};
