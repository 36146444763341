import type { FC } from 'react';
import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay/hooks';
import dynamic from 'next/dynamic';
import { useTranslation } from '@pafcloud/i18n';
import { Breakpoint, Color } from '@pafcloud/style';
import { ButtonLink, Link, Logo } from '@pafcloud/base-components';
import { useIsLoggedIn } from '@pafcloud/contexts';
import { useFlowRouter } from '@pafcloud/flow-router';
import { MessageMenu } from '../menus/message-menu';
import { HeaderColors } from '../header-colors';
import { BurgerComponent } from '../BurgerComponent';
import type { HeaderContentQuirkyPurple_content$key } from './__generated__/HeaderContentQuirkyPurple_content.graphql';
import { StyledHeader } from './shared';
import type { HeaderContentProps } from './shared';
import { HeaderMenu } from './HeaderMenu';
import { HeaderSearchBar } from './HeaderSearchBar';

const ProfileMenu = dynamic(() => import('../menus/profile-menu/ProfileMenu'), { ssr: false });

const contentFragment = graphql`
  fragment HeaderContentQuirkyPurple_content on Query {
    ...HeaderMenu_content
    ...HeaderSearchBar_data
    ...ProfileMenu_data
  }
`;

const Header = styled(StyledHeader)({
  position: 'fixed',
  justifyItems: 'flex-start',
  background: HeaderColors.Background,
  boxShadow: `${Color.Elevation.Level3}, 0 2px 6px -4px rgba(0, 0, 0, 1)`,
  overflow: 'hidden',
  paddingRight: 'var(--full-width-margin)',
  paddingLeft: 'var(--full-width-margin)',
});

const HeaderNavigation = styled(HeaderMenu)({
  display: 'none',
  height: '100%',
  margin: 0,

  [Breakpoint.LaptopOrLarger]: {
    display: 'grid',
  },
});

const ButtonSection = styled.div({
  display: 'grid',
  gridAutoFlow: 'column',
  gap: 8,
  alignItems: 'center',
  marginLeft: 'auto',
});

const LogoLink = styled(Link)({
  display: 'flex',
  position: 'relative',
  outline: 'none',

  ':focus-visible': {
    outline: `1px solid ${Color.Primitive.PrimaryContrast}`,
    outlineOffset: 2,
    borderRadius: 2,
  },
});

const HeaderLogo = styled(Logo)({
  maxWidth: '100%',
  height: 36,
  marginLeft: 4,
});

const BurgerButton = styled.button({
  '--burger-stroke-width': '2px',
  '--burger-gap': '5px',
  marginRight: 16,
  padding: '8px 0',
  border: 'none',
  color: Color.Primitive.PrimaryContrast,
  cursor: 'pointer',
  WebkitTapHighlightColor: 'transparent',
  background: 'transparent',
  outline: 'transparent',

  ':hover': {
    '--burger-gap': '6px',
    color: Color.Primitive.SecondaryTint,
  },

  ':focus-visible': {
    outline: `1px solid ${Color.Primitive.PrimaryContrast}`,
    outlineOffset: 2,
    borderRadius: 2,
  },

  '> span': {
    gap: 'var(--burger-gap)',
  },
});

const HeaderButtonLink = styled(ButtonLink)({
  '--button-text': Color.Primitive.Accent,
  '--button-background': Color.Primitive.AccentContrast,
  '--button-background--hover': Color.Primitive.AccentTint,
  '--button-background--active': Color.Primitive.AccentTint + '80',

  '--button-border': 'transparent',
  boxShadow: 'none',

  ':focus-visible': {
    outline: `2px solid ${Color.Primitive.AccentContrast}`,
    outlineOffset: 2,
  },

  minWidth: 92,
});

export const HeaderContent: FC<HeaderContentProps> = ({ isMainMenuOpen, setMainMenuOpen, ...props }) => {
  const content = useFragment<HeaderContentQuirkyPurple_content$key>(contentFragment, props.content);
  const { t } = useTranslation(['header', 'profile']);
  const { getFlowUrl } = useFlowRouter();
  const isLoggedIn = useIsLoggedIn();

  const menuAriaLabel = isMainMenuOpen ? t('main-menu-button.close.aria-label') : t('main-menu-button.open.aria-label');

  return (
    <Header>
      <BurgerButton
        onClick={() => setMainMenuOpen(!isMainMenuOpen)}
        aria-label={menuAriaLabel}
        aria-expanded={isMainMenuOpen}
        data-name="main-menu-button"
      >
        <BurgerComponent open={isMainMenuOpen} />
      </BurgerButton>

      <LogoLink aria-label={t('logo.aria-label')} href="/">
        <HeaderLogo />
      </LogoLink>

      <HeaderNavigation content={content} />

      <ButtonSection>
        <HeaderSearchBar data={content} />
        {isLoggedIn && (
          <>
            <MessageMenu />
            <ProfileMenu data={content} />
          </>
        )}
        {!isLoggedIn && (
          <HeaderButtonLink
            href={getFlowUrl('login')}
            aria-label={t('header-button.logged-out.aria-label')}
            size="default"
            variant="primary"
            type="button"
            data-testid="header-action"
            colorstyle="accent"
          >
            {t('header-button.logged-out.text')}
          </HeaderButtonLink>
        )}
      </ButtonSection>
    </Header>
  );
};
