import { createTransparentColor } from '../create-transparent-color';
import type { ColorDefinition } from './color-types';

const QuirkyPurplePalette = {
  Purple: '#533f86',
  PurpleLight: '#7463ac',
  PurpleHighlight: '#9284be',

  Yellow: '#fcee30',
  YellowLight: '#ffef6c',
  YellowHighlight: '#fdf695',

  Pink: '#ed1174',

  Aqua: '#3ec1cf',
  AquaLight: '#7ed9e3',
  AquaSuperLight: '#e7f6f8', // AquaTransparent15 on white returns this color
  AquaDark: '#098398',
  AquaBlack: '#0d2c2d',

  // Neutral
  Neutral: '#798486',
  NeutralLighter: '#c8d8db',
  NeutralLight: '#a7b4b6',
  NeutralDark: '#43494a',
  NeutralBlack: '#24292a',

  Black: '#000000',
  White: '#ffffff',

  // Extra
  Aux: '#FF206E',
};

const Primitive: ColorDefinition['Primitive'] = {
  Primary: QuirkyPurplePalette.Purple,
  PrimaryTint: QuirkyPurplePalette.PurpleLight,
  PrimaryContrast: QuirkyPurplePalette.White,

  Secondary: QuirkyPurplePalette.Yellow,
  SecondaryTint: QuirkyPurplePalette.YellowLight,
  SecondaryContrast: QuirkyPurplePalette.NeutralBlack,

  Accent: QuirkyPurplePalette.AquaDark,
  AccentTint: QuirkyPurplePalette.Aqua,
  AccentContrast: QuirkyPurplePalette.White,

  Gradient: QuirkyPurplePalette.Purple,
  GradientTint: QuirkyPurplePalette.PurpleLight,
  GradientContrast: QuirkyPurplePalette.White,
};

const TextColors: ColorDefinition['TextColors'] = {
  HeadingText: QuirkyPurplePalette.Purple,
  BodyText: QuirkyPurplePalette.Black,
  MutedText: QuirkyPurplePalette.Neutral,
  HighlightedText: QuirkyPurplePalette.AquaBlack,
  LinkText: QuirkyPurplePalette.Purple,
  ErrorText: QuirkyPurplePalette.Pink,
  DisabledText: QuirkyPurplePalette.NeutralLight,
};

const Surface: ColorDefinition['Surface'] = {
  Base: {
    Background: QuirkyPurplePalette.White,
    Foreground: QuirkyPurplePalette.Black,
    Dimmed: createTransparentColor(QuirkyPurplePalette.AquaLight, 0.5),
  },
  Nested: {
    Background: QuirkyPurplePalette.AquaSuperLight,
    Foreground: QuirkyPurplePalette.AquaBlack,
    Dimmed: createTransparentColor(QuirkyPurplePalette.Aqua, 0.3),
  },
  Disabled: {
    Background: QuirkyPurplePalette.NeutralLighter,
    Foreground: QuirkyPurplePalette.Neutral,
    Dimmed: createTransparentColor(QuirkyPurplePalette.NeutralDark, 0.15),
  },
  Floating: {
    Background: QuirkyPurplePalette.White,
    Foreground: QuirkyPurplePalette.Black,
    Dimmed: createTransparentColor(QuirkyPurplePalette.Purple, 0.15),
  },
};

const Signal: ColorDefinition['Signal'] = {
  Success: QuirkyPurplePalette.Purple,
  SuccessContrast: QuirkyPurplePalette.White,

  Info: QuirkyPurplePalette.AquaLight,
  InfoContrast: QuirkyPurplePalette.Black,

  Attention: QuirkyPurplePalette.Yellow,
  AttentionContrast: QuirkyPurplePalette.Black,

  Danger: QuirkyPurplePalette.Aux,
  DangerContrast: QuirkyPurplePalette.White,
};

const CornerRadius: ColorDefinition['CornerRadius'] = {
  Small: '4px',
  Base: '12px',
  Large: '32px',
};

const Elevation: ColorDefinition['Elevation'] = {
  Level1: `
    0 0 0 1px ${createTransparentColor(QuirkyPurplePalette.AquaLight, 0.5)},
    0 2px 5px 0 var(--shadow-color, ${createTransparentColor(QuirkyPurplePalette.Purple, 0.4)})
  `,
  Level2: `0 3px 8px -2px var(--shadow-color, ${createTransparentColor(QuirkyPurplePalette.Purple, 0.3)})`,
  Level3: `0 8px 12px -2px var(--shadow-color, ${createTransparentColor(QuirkyPurplePalette.Purple, 0.2)})`,
};

const General: ColorDefinition['General'] = {
  Bonus: QuirkyPurplePalette.Pink,
  BonusContrast: QuirkyPurplePalette.White,
};

const Layout: ColorDefinition['Layout'] = {
  Background: QuirkyPurplePalette.AquaSuperLight,
  BrandBorder: `linear-gradient(90deg, ${Primitive.Primary}, ${Primitive.PrimaryTint})`,
  BrowserTheme: Primitive.AccentTint,
  LoadingColor: Primitive.Primary,
  Overlay: QuirkyPurplePalette.AquaBlack + 'C0',
  Sidebar: {
    Background: `linear-gradient(180deg, ${Primitive.Accent}, ${Primitive.AccentTint})`,
    Foreground: QuirkyPurplePalette.White,
  },
};

const Hero: ColorDefinition['Hero'] = {
  Heading: QuirkyPurplePalette.White,
  Text: QuirkyPurplePalette.White,
  TextHighlight: QuirkyPurplePalette.White,

  Button: {
    Solid: Primitive.Primary,
    Rest: Primitive.Primary,
    Hover: Primitive.PrimaryTint,
    Active: Primitive.PrimaryTint,
    Text: Primitive.PrimaryContrast,
  },
};

export const Color: ColorDefinition = {
  TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,
};
