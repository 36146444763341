import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  BackgroundBlur: Color.Primitive.Accent,
  Background: Color.Primitive.Accent,

  Icon: Color.Primitive.AccentContrast + 'D0',
  IconFocus: Color.Primitive.AccentContrast,

  NotificationBackground: Color.Primitive.Secondary,
  NotificationText: Color.Primitive.SecondaryContrast,
};
