import { Color } from '@pafcloud/style';

export const Colors = {
  Text: Color.Surface.Base.Background,
  TextHover: Color.Primitive.Secondary,
  TextActive: Color.Primitive.Secondary,
  TextShadow: `3px 3px 0 ${Color.Primitive.Primary}80`,

  Icon: undefined,
  IconActive: undefined,

  BackgroundHover: undefined,
  BackgroundActive: undefined,

  ItemDivider: undefined,
  ItemActiveShadow: undefined,

  SubMenuBackground: undefined,
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: 'uppercase',
  Weight: 900,
  WeightHover: 900,
  WeightActive: 900,
} as const;
